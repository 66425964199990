import React from 'react';

import * as contentStackInterfaces from '@shared/contentstack/contentstack.interface';
import {
  filterDefinedComponents,
  filterDefinedBanners,
  mappedComponent,
} from '@shared/utilities/page.util';

const pageDataFallBack = {
  components: [],
  hero_banner: [],
  site_configuration: null,
};

const PageLayout = ({
  pageContext,
}: {
  pageContext: contentStackInterfaces.CSPageContext;
}) => {
  const { components, hero_banner, site_configuration } =
    pageContext || pageDataFallBack;

  return [
    ...filterDefinedBanners(hero_banner),
    ...filterDefinedComponents(components),
  ]?.map((comp, index) => {
    return mappedComponent(
      comp.type,
      { ...comp, site_configuration, variant: comp.type },
      index
    );
  });
};

export default PageLayout;
